// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

import { installationPartnerRealmEnvironment } from '@abb-emobility/oms/installation-partner-oauth';

export const environment = {
	production: false,
	// oauthRealm is set before feeding the env provider in the main component
	oauthRealm: '',
	oauthClientId: '',
	// installationPartnerPath is set before feeding the env provider in the main component
	installationPartnerPath: '',
	installationPartnerRealms: installationPartnerRealmEnvironment
};
